<template>
  <div class="px-5" id="page-support">
    <h5 class="mb-6 ml-2">Systeminställningar</h5>
    
      <div class="card card-custom card-stretch gutter-b">
        <!--begin::Header-->
        <div class="card-header border-0 py-5">
          <h3 class="card-title align-items-start flex-column">
            <span class="card-label font-weight-bolder text-dark"></span>
          </h3>
          <div class="card-toolbar">

          </div>
        </div>
        <!--end::Header-->
        <!--begin::Body-->
        <div class="card-body pt-0 pb-3">

          <!--begin::Table-->
          <div class="table-responsive">
            <table
              class="table table-head-custom table-vertical-center table-head-bg table-borderless"
            >
              <tbody>
                <tr style="background-color: black; color: white;">
                    <td>Föreningar</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                  <td>Systemförening</td>
                  <td>
                      <b-form-select
                        v-model="system_company_id"
                        :options="parent_companies"
                      ></b-form-select>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>Riksförbund mall</td>
                  <td>
                      <b-form-select
                        v-model="riks_company_id"
                        :options="parent_companies"
                      ></b-form-select>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>Studentkår mall</td>
                  <td>
                      <b-form-select
                        v-model="student_company_id"
                        :options="parent_companies"
                      ></b-form-select>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>Förening mall</td>
                  <td>
                      <b-form-select
                        v-model="default_company_id"
                        :options="parent_companies"
                      ></b-form-select>
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
          <!--end::Table-->
        </div>
        <!--end::Body-->
      </div>

    <b-button type="button" variant="primary" @click="saveSystem">Spara</b-button>

  </div>
</template>

<style lang="scss" scoped>
.checkbox.checkbox-light-success > span {
  background-color: white;
  border: 1px solid #ddd;
}
</style>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
  name: 'system',
  components: {

  },
  mounted() {
  },
  data() {
    return {
    
    };
  },
  watch: {
    
  },
  methods: {
    toastr(type, title, message) {
      this.$bvToast.toast(message, {
        title: title,
        autoHideDelay: 2000,
        appendToast: true,
        variant: type
      });
    }
  }
};
</script>


